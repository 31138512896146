import truffleIcon from "../../../../src/posts/hardhat-vs-truffle-vs-remix/truffle.png";
import hardhatIcon from "../../../../src/posts/hardhat-vs-truffle-vs-remix/hardhat.png";
import remixIcon from "../../../../src/posts/hardhat-vs-truffle-vs-remix/remix.png";
import ganache from "../../../../src/posts/hardhat-vs-truffle-vs-remix/ganache.png";
import githubEdit from "../../../../src/posts/hardhat-vs-truffle-vs-remix/github-edit.png";
import remixPlugins from "../../../../src/posts/hardhat-vs-truffle-vs-remix/remix-plugins.png";
import remixTest from "../../../../src/posts/hardhat-vs-truffle-vs-remix/remix-test.png";
import remixTestcustomization from "../../../../src/posts/hardhat-vs-truffle-vs-remix/remix-test-customization.png";
import solCompiler from "../../../../src/posts/hardhat-vs-truffle-vs-remix/sol-compiler.png";
import blockchainFramewokrsResult from "../../../../src/posts/hardhat-vs-truffle-vs-remix/popular-blockchain-frameworks.png";
import remixIde from "../../../../src/posts/hardhat-vs-truffle-vs-remix/remix-ide.png";
import * as React from 'react';
export default {
  truffleIcon,
  hardhatIcon,
  remixIcon,
  ganache,
  githubEdit,
  remixPlugins,
  remixTest,
  remixTestcustomization,
  solCompiler,
  blockchainFramewokrsResult,
  remixIde,
  React
};